<template>
  <div>
    <v-app-bar app elevation="0" class="white">
       <v-btn v-if="mode ==='view'" icon @click="$router.push({name: 'user'})"><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card tile elevation="0">
              <!--     <v-card-title>
            ข้อกำหนดและเงื่อนไขให้บริการ
        </v-card-title> -->
              <v-card-text v-html="data.contentTh"> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
    <v-footer app height="">
      <v-row justify="center" no-gutters>
        <v-col cols="12">
          <div>
            <!-- <v-checkbox v-model="agree" :label="'ยอมรับทั้งหมด'"></v-checkbox> -->
             <v-radio-group
      v-model="agree"
      row
      class="mx-auto"
    >
    <v-spacer/>
      <v-radio
        label="ยินยอม"
        :value= true
      ></v-radio>
       <v-spacer/>
       <v-spacer/>
      <v-radio
        label="ไม่ยินยอม"
        :value= false
      ></v-radio>
       <v-spacer/>
    </v-radio-group>
          </div>
        </v-col>
         <v-btn v-if="mode ==='view'" x-large height="58"  block color="primary" @click="submit">ตกลง</v-btn>
         <v-btn v-else x-large height="58"  block color="primary" @click="submit">ตกลง</v-btn>
        <v-col cols="12">
          <div class="text-center"></div>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { pdpaContent, consent } from "@/api";
import { mapFields } from "vuex-map-fields";

export default {
  props: {
    mode: {
      type: String
    }
  },
  data() {
    return {
      data: "",
      loading: false,
      role: null,
      agree: false
    };
  },
  computed: {
    ...mapFields(['pn'])
  },
  methods: {
    submit() {
      this.loading = true
      this.role = this.$offlineStorage.get("role");
      let data = {
        contentId: this.data.id,
        contentType: this.data.contentType,
        version: this.data.version,
        versionDate: this.data.versionDate,
        isAccept: this.agree,
      };

      consent(
        data,
        (message) => {
          if (message.data.code === 1) {
                 this.pn = true
                 this.$router.replace({name: 'cookies'})
                 //this.$offlineStorage.set("isAuthen", true);
                 //this.$auth.buildAllSite()
                 // this.$router.replace({name: 'home'});
          } 
          this.loading = false
        },
        (error) => {
          console.log(error);
          this.loading = false
        }
      );
    },
    fetchData() {
      this.loading = true;
      if (this.mode === 'view') this.agree = this.$auth.role.consent.privacyPolicy.isAccept
      pdpaContent(
        "privacy_policy",
        (message) => {
          if (message.data.code === 1) {
            this.data = message.data.result[0];
          } else {
            this.data = [];
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>